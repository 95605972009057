<template>
  <b-card title="Tambah Soal ➕">
    <b-form @submit.prevent>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Judul Artikel"
            label-for="v-judul"
          >
            <b-form-input
              id="v-judul"
              v-model="data.judul"
              placeholder="Judul Artikel"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
            <b-form-group
                label="File Gambar Artikel"
                label-for="v-file-gambar"
            >
            <b-form-file
                v-model="data.file_gambar"
                placeholder="Pilih File "
                drop-placeholder="Letakkan di sini."
            />
            </b-form-group>
        </b-col>
        <b-col cols="12">
            <b-form-group
                label="Isi Artikel"
                label-for="v-isi"
                label-size="lg"
                label-class="font-weight-bold"
            >
            <quill-editor
                id="isi"
                rows="4"
                v-model="data.isi"
            />
          </b-form-group>
        </b-col>
        <!-- submit and reset -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="saveData()"
          >
            Submit
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor, Quill } from 'vue-quill-editor'

const Block = Quill.import('blots/block')
Block.tagName = 'span'
Quill.register(Block)

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormFile,
    quillEditor,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      data: {
        judul: '',
        file_gambar: '',
        isi: '',
      },
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    saveData() {
      const data = new FormData()
      data.append('judul', this.data.judul)
      data.append('file_gambar', this.data.file_gambar)
      data.append('isi', this.data.isi)

      this.$http.post('/blog', data).then(response => {
        if (response.data.status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          setTimeout(() => {
            this.$router.push({ name: 'artikel-index' })
          }, 500)
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
